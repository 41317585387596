import fetch from 'cross-fetch';

import notificationService from '../services/notificationService';

const objToQueryString = (obj) => Object.keys(obj).map(key => key + '=' + obj[key]).join('&');


const commonRequest = async (url, params) => {

  let data;

  try {    
    const res = await fetch(url, params);
    data = await res.json();
  } catch(err) {
    console.warn(err);
    return null;
  }  

  return data;
};

export const commonPost = (url, body = {}) => commonRequest(url, {
  method: 'POST',
    headers: {
      'pragma': 'no-cache',
      'cache-control': 'no-cache',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
});

export const commonGet = (url, body = {}) => commonRequest(`${url}?${objToQueryString(body)}`, {
  method: 'GET',
  headers: {
    'pragma': 'no-cache',
    'cache-control': 'no-cache',
  },
});
