import zipCache from '../helpers/zipCache';

class gMapsService {

  constructor() {

    if (!window.isGMapsLoaded) {

      (() => {

        if (!window.gm_api_key) {
          console.warn('google maps loading issue');
          this.loadingFailed = true;
        } else {
  
          const getApiUrl = (key) => `https://maps.googleapis.com/maps/api/js?key=${key}`;
      
          const apiUrl = getApiUrl(window.gm_api_key);
      
          const scriptEl = document.createElement('script');
          scriptEl.src = apiUrl;
      
          document.body.appendChild(scriptEl);

          window.isGMapsLoaded = true;
          window.gMapsService = this;
          
        }
  
      })();
    }


  }

  getZip = async ({
    lat,
    lon,
  }) => new Promise((resolve, reject) => {

    if (!lat || !lon) {
      return reject('gMapService: getZip params issue');
    }

    var geocoder = new google.maps.Geocoder;

    const location = new google.maps.LatLng(lat, lon);
    const locationString = JSON.stringify(location);

    const cacheZipValue = zipCache.getZip(locationString);

    if (cacheZipValue) {
      // console.warn('get zip from cache', locationString);
      return resolve(cacheZipValue);
    }

    geocoder.geocode({'location': location}, function(results, status) {
      if (status === 'OK') {              
        const zip = this.getZipFromGeocodeResults(results);

        zipCache.pushZip({locationString, zip});
        // console.warn('push zip to cache', locationString);
        
        return resolve(zip);
        
      } else {
        return reject('gMapService: getZip Geocode call failed');
      }
    }.bind(this));

  });

  getZipFromGeocodeResults = (results = []) => {
    
    if (!results.length) {
      return null;
    }

    const postalCode = results[0].address_components.find(a => a.types.includes('postal_code'));

    return postalCode ? postalCode.short_name : null;

  }

}

export default window.gMapsService || new gMapsService();