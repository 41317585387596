class ZipCache {
  constructor() {
    
    const localStorageCache = localStorage.getItem('zipCache');

    try {
      this.cache = JSON.parse(localStorageCache) || {};
      // console.warn('zip cache restored');
    } catch {
      this.cache = {};
      // console.warn('zip cache restore error');
    }

  }

  pushZip = ({
    locationString,
    zip
  }) => {
    if (!locationString || !zip) {
      return;
    }

    this.cache[locationString] = zip;
    this.updateLocalStorage();
  };

  getZip = (locationString) => this.cache[locationString] ? this.cache[locationString] : null;

  updateLocalStorage = () => {
    const cacheString = JSON.stringify(this.cache);
    localStorage.setItem('zipCache', cacheString);
  }

}

export default new ZipCache();