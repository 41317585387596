export const fillTemplate = (templateString, templateVars) => {
  return templateString ? templateString.replace(/{([^{}]*)}/g,
        function(a, b) {
            var r = templateVars[b];
            return typeof r === 'string' || typeof r === 'number' ? r : a;
        }
    ) : '';
};

export const formatZip = (zip) => {
    if (!zip) {
        return '';
    }

    return zip.length === 9 ? `${zip.slice(0, 5)}-${zip.slice(5, 9)}` : zip;
};

export const formatPhone = (phone) => {

    const trimmed = phone.replace(/[^0-9]/g, '');
  
    if (trimmed.length === 10) {
      return `${trimmed.slice(0, 3)}-${trimmed.slice(3, 6)}-${trimmed.slice(6, 10)}`;
    } else {
      return phone;
    }
  
};

export const formatMoney = (amount, decimalCount = 2, decimal = '.', thousands = ',') => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    decimalCount = Number.isInteger(amount) ? 0 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '');
  } catch (e) {
    console.log(e);
  }
};

export const formatDate = (date) => {

  let m = {};

  if (/Date/.test(date)) {
    m = new Date(Number.parseInt(date.match(/\d{13}/g)[0]));
  } else {
    m = new Date(date);
  }  
   
  var dateString = `${(m.getUTCMonth() + 1)}.${m.getUTCDate()}.${m.getUTCFullYear()}`;
    
  return dateString;
};

export const removeTags = (str) => str ? str.replace(/(<([^>]+)>)/ig, '') : '';

export const generateSpacingClasses = (containerClass, blockData) => `${containerClass}--top-margin-${blockData.MarginTop} ${containerClass}--bottom-margin-${blockData.MarginBottom} ${containerClass}--top-padding-${blockData.PaddingTop} ${containerClass}--bottom-padding-${blockData.PaddingBottom}`;