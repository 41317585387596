function formatNumber(n) {
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const formatCurrency = (input, blur) => {
  var input_val = input.val();

  if (input_val === '') { return; }

  var original_len = input_val.length;
  var caret_pos = input.prop('selectionStart');

  if (input_val.indexOf('.') >= 0) {
    var decimal_pos = input_val.indexOf('.');
    var left_side = input_val.substring(0, decimal_pos);
    var right_side = input_val.substring(decimal_pos);
    left_side = formatNumber(left_side);
    right_side = formatNumber(right_side);

    if (blur === 'blur') {
      right_side += '00';
    }
    right_side = right_side.substring(0, 2);
    input_val = '$' + left_side + '.' + right_side;

  } else {
    input_val = formatNumber(input_val);
    input_val = '$' + input_val;

    if (blur === 'blur') {
      input_val += '.00';
    }
  }

  input.val(input_val);

  if (blur !== 'blur') {
    var updated_len = input_val.length;
    caret_pos = updated_len - original_len + caret_pos;
    input[0].setSelectionRange(caret_pos, caret_pos);
  }
};

/* Phone input */

export const formatPhoneNumber = (phoneNumberString) => {
  const phone = phoneNumberString.replace(/[^\d]/g, '');

  if (phone.length == 10) {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  return phoneNumberString;
};

export const clearPhoneNumber = (e) => {
  e.target.value = e.target.value.replace(/[^0-9]/g, '');
};

export const phoneNumberLabels = {
  'required': 'Phone is required',
  'pattern': 'Please enter a valid phone number'
};

export const phoneNumberRules = {
  required: true, 
  pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
};

/* Zip input */

export const clearZip = (e) => {
  e.target.value = e.target.value.replace(/[^0-9]/g, '');
};

export const zipRules = {
  required: true, 
  maxLength: 5, 
  minLength: 5, 
  pattern: /\d+/
};

export const zipErrorsLabels = {
  'maxLength': 'zip code should be 5 digits',
  'minLength': 'zip code should be 5 digits',
  'required': 'zip code is required',
  'pattern': 'zip code is numbers only',
};