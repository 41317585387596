import {serializeQuery} from '../helpers/urlHelper';

class BrowserHistoryService {
  constructor() {
    
  }
  
  getQueryParams = () => {
    
  }
  
  updateQueryParams = (params) => {
    window.history.pushState(params, '' , `?${serializeQuery(params)}`);

  }
}

export default new BrowserHistoryService();