class NotificationService {

  constructor() {
    window.notificationService = this;
  }

  notify = (message) => {

    const messages = Array.isArray(message) ? message : [message];

    if (window.TemplateNotificationModal) {
      window.TemplateNotificationModal.showMessages(messages);
    }
  }
}

export default window.notificationService || new NotificationService();
