import gMapService from './gMapService';
import notificationService from './notificationService';

const ZIP_DETECT_ERROR_MESSAGE = 'Unable to retrieve your location';

class LocationService {

  constructor() {
    console.log('LocationService');
    window.locationService = this;
    this.updateHeaderZip();
  }

  getSessionZip = () => sessionStorage.getItem('currentZipCode');

  setSessionZip = (zip) => {
    if (zip) {
      sessionStorage.setItem('currentZipCode', zip);
      this.updateHeaderZip();
    }
  }

  updateHeaderZip = () => {
    const headerZip = document.getElementById('headerZip');
    const zip = sessionStorage.getItem('currentZipCode');
    headerZip.innerHTML = zip ? zip : '';
  }

  detectLocationZip = async () => {

    let zip = null;

    try {
      const location = await this.detectLocation();
      zip = await gMapService.getZip(location);
    } catch(e) {
      console.warn(e);
      notificationService.notify(ZIP_DETECT_ERROR_MESSAGE);
    }

    return zip;
  };

  detectLocation = () => new Promise((resolve, reject) => {
    const success = (position) => {
      const lat  = position.coords.latitude;
      const lon = position.coords.longitude;
      return resolve({
        lat,
        lon,
      });
    };
  
    const error = () => {
      reject('Unable to retrieve your location');
    };
  
    if (!navigator.geolocation) {
      return reject('Geolocation is not supported by your browser');
    } else {
      navigator.geolocation.getCurrentPosition(success, error);
    }
  });

}

export default window.locationService || new LocationService();