class ScrollService {

  constructor() {
    this.actions = [];
    this.scrollId = null;
    window.addEventListener('scroll', this.onscroll);
  }

  onscroll = (e) => {
    clearTimeout(this.scrollId);
    this.scrollId = setTimeout(() => {
      this.actions.forEach((f) => {
        f(e);
      });
    }, 100);
  }

  addAction = (action) => {
    this.actions.push(action);
  }

  removeAction = (action) => {
    this.actions = this.actions.filter(a => a !== action);
  }

}

export default new ScrollService();