export const chunkify = (array, cols, balanced) => {
  
  if (cols < 2) {
    return [array];
  }

  var len = array.length,
          out = [],
          i = 0,
          size;
  if (len % cols === 0) {
      size = Math.floor(len / cols);
      while (i < len) {
          out.push(array.slice(i, i += size));
      }
  }
  else if (balanced) {
      while (i < len) {
          size = Math.ceil((len - i) / cols--);
          out.push(array.slice(i, i += size));
      }
  }
  else {
      cols--;
      size = Math.floor(len / cols);
      if (len % size === 0) {
        size--;
      }
      while (i < size * cols) {
          out.push(array.slice(i, i += size));
      }
      out.push(array.slice(size * cols));
  }
  return out;
};