export const getScrollPosition = () => document.documentElement.scrollTop || document.body.scrollTop;
export const getElementPosition = (element) => {
  const bodyRect = document.body.getBoundingClientRect();
  const rect = element.getBoundingClientRect();
  const top = rect.top - bodyRect.top;
  const bottom = top + rect.height;

  return {
    top,
    bottom,
  };
};

export const getViewPortDimentions = () => ({
  vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
  vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
});

export const getCurrentBreakpoint = () => {
  const {
    vw,
  } = getViewPortDimentions();

  if (vw < 576) {
    return 'xs';
  } else if (vw < 768) {
    return 'sm';
  } else if (vw < 992) {
    return 'lg';
  }

  return 'xl';
};