//Resolves an issue where carousel/slide components did not always properly re-render upon
//orientation change which would break the page layout and force content off-screen to the left.

void new class {

    constructor() {
        window.addEventListener('orientationchange', this.onOrientationChange);
    }

    onOrientationChange = () => {
        document.body.style.display = 'none';
        document.body.offsetHeight; //trigger a reflow
        document.body.style.display = 'block'; //trigger a repaint
    }

};
