import {
  getScrollPosition,
  getElementPosition,
  getViewPortDimentions,
} from './positionHelper';

const OPACITY_STEPS = 10;

const ifShouldBeShown = (element) => {
  const scrollPosition = getScrollPosition();
  const elementPosition = getElementPosition(element);
  const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  const offsetTop = elementPosition.top;
  return scrollPosition + (vh / 4) * 3 > offsetTop;
};

const calcFadeInOutStepOpacity = (element) => {
  let opacity = 1;

  const scrollPosition = getScrollPosition();
  const elementPosition = getElementPosition(element);
  const {
    vh,
  } = getViewPortDimentions();
  const fourth = vh / 4;
  const six = vh / 6;
  const fiveSix = six * 5;
  const threeFourth = fourth * 3;
  const threeFourthPosition = scrollPosition + threeFourth;
  const sixPosition = scrollPosition + six;
  const fiveSixPosition = scrollPosition + fiveSix;

  if (elementPosition.bottom < scrollPosition || elementPosition.top > (scrollPosition + vh)) {
    return 0;
  }

  if (elementPosition.top < sixPosition && elementPosition.bottom > fiveSixPosition) {
    return 1;
  }
  
  if (elementPosition.top > threeFourthPosition) {
    const opacityStep = fourth / OPACITY_STEPS;
    const elementOffsetFromTop = elementPosition.top - threeFourthPosition;
    return 1 - Number(((elementOffsetFromTop / opacityStep) / 10).toFixed(1));
  }
  
  if (elementPosition.bottom < sixPosition) {
    const opacityStep = six / OPACITY_STEPS;
    const elementOffsetFromTop = elementPosition.bottom - scrollPosition;
    return Number(((elementOffsetFromTop / opacityStep) / 10).toFixed(1));
  }

  return opacity;
};

const calcFadeInOutOpacity = (el) => {
  const scrollPosition = getScrollPosition();
  const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

  const half = vh / 2;
  const fourth = vh / 4;
  const threeFourth = fourth * 3;
  
  const bodyRect = document.body.getBoundingClientRect();
  const rect = el.getBoundingClientRect();
  const offsetTop = rect.top - bodyRect.top;
  const offsetBottom = offsetTop + rect.height;

  const viewCenter = scrollPosition + half;
  const scrollViewRectTop = scrollPosition + fourth;
  const scrollViewRectBottom = scrollPosition + threeFourth;

  const scrollTopInElement = scrollViewRectTop > offsetTop && scrollViewRectTop < offsetBottom;
  const scrollBottomInElement = scrollViewRectBottom > offsetTop && scrollViewRectBottom < offsetBottom;
  const inMiddle = scrollViewRectTop > offsetTop && scrollViewRectBottom < offsetBottom;
  
  return scrollTopInElement || scrollBottomInElement || inMiddle  ? 1 : 0;
};

const calcFadeOutStepOpacity = (element) => {
  let opacity = 1;

  const scrollPosition = getScrollPosition();
  const elementPosition = getElementPosition(element);
  const {
    vh,
  } = getViewPortDimentions();
  const fourth = vh / 4;
  const threeFourth = fourth * 3;
  const threeFourthPosition = scrollPosition + threeFourth;

  if (elementPosition.bottom < scrollPosition) {
    return 0;
  }
  
  if (elementPosition.bottom < threeFourthPosition) {
    const opacityStep = threeFourth / OPACITY_STEPS;
    const elementOffsetFromTop = elementPosition.bottom - scrollPosition;
    opacity = Number(((elementOffsetFromTop / opacityStep) / 10).toFixed(1));
  }

  return opacity;
};

const calcFadeOutOpacity = (element) => {
  let opacity = 1;

  const scrollPosition = getScrollPosition();
  const elementPosition = getElementPosition(element);
  const {
    vh,
  } = getViewPortDimentions();
  const fourth = vh / 4;
  const threeFourth = fourth * 3;
  const fourthPosition = scrollPosition + fourth;

  if (elementPosition.bottom < fourthPosition) {
    opacity = 0;
  }

  return opacity;
};

const calcFadeInStepOpacity = (element) => {
  let opacity = 1;

  const scrollPosition = getScrollPosition();
  const elementPosition = getElementPosition(element);
  const {
    vh,
  } = getViewPortDimentions();
  const fourth = vh / 4;
  const threeFourth = fourth * 3;
  const threeFourthPosition = scrollPosition + threeFourth;

  if (elementPosition.top > (scrollPosition + vh)) {
    return 0;
  }

  if (elementPosition.top > threeFourthPosition) {
    const opacityStep = fourth / OPACITY_STEPS;
    const elementOffsetFromTop = elementPosition.top - threeFourthPosition;
    return 1 - Number(((elementOffsetFromTop / opacityStep) / 10).toFixed(1));
  }

  return opacity;
};

const calcFadeInOpacity = (element) => ifShouldBeShown(element) ? 1 : 0;

export class FadeInOutElement {
  constructor({
    element, 
    triggerElement,
    method = 'step',
  }) {

    this.triggerElement = triggerElement || element;
    this.calcOpacity = method == 'step' ? calcFadeInOutStepOpacity : calcFadeInOutOpacity;

    if (element) {

      if (method == 'transition') {
        element.style.transition = 'opacity .7s';
      }

      element.style.opacity = this.calcOpacity(this.triggerElement);

      const onScroll = (e) => {
        element.style.opacity = this.calcOpacity(this.triggerElement);
      };

      window.addEventListener('scroll', onScroll);
    }
  }
}

export class FadeInOnceElement {
  constructor({
    element,
    triggerElement,
    delay = 0,    
  }) {

    this.triggerElement = triggerElement || element;

    if (element) {

      element.style.transition = 'opacity .7s';
      element.style.opacity = 0;

      const onScroll = (e) => {
        if (ifShouldBeShown(this.triggerElement)) {
          window.removeEventListener('scroll', onScroll);
          if (delay) {
            setTimeout(() => {
              element.style.opacity = 1;
            }, delay);
          } else {
            element.style.opacity = 1;
          }
        }
      };

      window.addEventListener('scroll', onScroll);
    }
    
  }

}

export class FadeOutElement {
  constructor({
    element, 
    triggerElement,
    method = 'step',
  }) {

    this.triggerElement = triggerElement || element;
    this.calcOpacity = method == 'step' ? calcFadeOutStepOpacity : calcFadeOutOpacity;

    if (element) {

      if (method == 'transition') {
        element.style.transition = 'opacity .7s';
      }

      element.style.opacity = this.calcOpacity(this.triggerElement);

      const onScroll = (e) => {
        element.style.opacity = this.calcOpacity(this.triggerElement);
      };

      window.addEventListener('scroll', onScroll);
    }
  }
}

export class FadeInElement {
  constructor({
    element, 
    triggerElement,
    method = 'step',
  }) {

    this.triggerElement = triggerElement || element;
    this.calcOpacity = method == 'step' ? calcFadeInStepOpacity : calcFadeInOpacity;

    if (element) {

      if (method == 'transition') {
        element.style.transition = 'opacity .7s';
      }

      element.style.opacity = this.calcOpacity(this.triggerElement);

      const onScroll = (e) => {
        element.style.opacity = this.calcOpacity(this.triggerElement);
      };

      window.addEventListener('scroll', onScroll);
    }
  }
}

export const applyFadeAnimation = (type, element, triggerElement) => {

  if (!element) {
    return;
  }

  switch(type) {
    case 'fade-in':
      new FadeInElement({
        element,
        triggerElement,
      });
      break;      
    case 'fade-in-once':
      new FadeInOnceElement({
        element,
        triggerElement,
      });
      break;      
    case 'fade-in-out':
      new FadeInOutElement({
        element,
        triggerElement,
      });
      break;      
    case 'fade-out':
      new FadeOutElement({
        element,
        triggerElement,
      });
      break;      
    default:
      break;
  }
};