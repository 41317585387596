class ResizeService {

  constructor() {
    this.actions = [];
    this.resizeId = null;
    window.addEventListener('resize', this.onResize);
  }

  onResize = () => {
    clearTimeout(this.resizeId);
    this.resizeId = setTimeout(() => {
      this.actions.forEach((f) => {
        f();
      });
    }, 500);
  }

  addAction = (action) => {
    this.actions.push(action);
  }

}

export default new ResizeService();